import type { ErrorPlatformEvent } from 'xstate'

import { useSalesCustomerCareLogger } from '~/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

export const logServiceError = (label: string, event: ErrorPlatformEvent) => {
  const { logHttpError } = useSalesCustomerCareLogger()

  logHttpError({
    error: event.data,
    errorStatusCode: event.data?.status ?? 400,
    errorTitle: label,
  })
}
