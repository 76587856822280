import { SALES_CUSTOMER_CARE_SCOPE } from '~/scopes/care-commons/utils/logger/salesCustomerCare/config'
import { useSalesCustomerCareLogger } from '~/scopes/care-commons/utils/logger/salesCustomerCare/useSalesCustomerCareLogger'

export interface IssuesCommunication {
  selectedIssues: string[]
  tags: string[]
}

export function encodeIssues(issues: IssuesCommunication) {
  const { logError } = useSalesCustomerCareLogger()

  try {
    const json = JSON.stringify(issues)
    const base64 = btoa(json)

    return base64
  } catch (error) {
    logError({
      errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.diagnosis} Error occured when encoding issues`,
      attributes: {
        error: error as Error,
        issues,
      },
    })
    throw error
  }
}

export function decodeIssues(issuesBase64String: string) {
  const { logError } = useSalesCustomerCareLogger()

  try {
    const rawString = atob(issuesBase64String)
    const json: IssuesCommunication = JSON.parse(rawString)

    return json
  } catch (error) {
    logError({
      errorTitle: `${SALES_CUSTOMER_CARE_SCOPE.diagnosis} Error occured when decoding issues`,
      attributes: {
        error: error as Error,
        issues: issuesBase64String,
      },
    })
    throw error
  }
}
